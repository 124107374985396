import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import Img from 'gatsby-image/withIEPolyfill';
import React from 'react';

import { Breakpoint, Project } from '../../types';
import { forBreakpoint } from '../../utils/forBreakpoint';
import { Column } from '../UI/Column';
import { Row } from '../UI/Row';
import { Text } from '../UI/Text';

type Props = {
  project: Project
  flexDirection?: string;
};

export function ProjectOverview({ project, flexDirection }: Props) {
  return (
    <>
      <Container>
        <ImagesContainer>
          <Row direction={flexDirection}>
            <Column direction="column" breakpoints={{ [Breakpoint.TabletLandscape]: 6 }}>
              <StyledImg fluid={project.ImagesGallery[0].asset.fluid} alt="Logo" />
            </Column>
            <Column direction="column" breakpoints={{ [Breakpoint.TabletLandscape]: 6 }}>
              <Logo fluid={project.ImagesGallery[1].asset.fluid} alt="Screenshot" />
            </Column>
          </Row>
        </ImagesContainer>
        <ClientContainer>
          <Row>
            <StyledColumn direction="column" breakpoints={{ [Breakpoint.TabletLandscape]: 5 }}>
              <Text mode="client">{project.Client}</Text>
            </StyledColumn>
            <Column direction="row" breakpoints={{ [Breakpoint.TabletLandscape]: 7 }}>
              {project.Tags.map((tag, key) => {
                return (
                  <Tag key={key} mode="tag">{tag}</Tag>);
              })
              }
            </Column>
          </Row>
        </ClientContainer>
      </Container>
    </>
  );
}

const Container = styled.div`
  ${forBreakpoint(Breakpoint.Phone, css`
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    margin-bottom: 4rem;
  `)};
    ${forBreakpoint(Breakpoint.TabletLandscape, css`
    margin: 0 0 3rem 0;
  `)};
`;

const ImagesContainer = styled.div`
  margin-bottom: 1rem;
`;

const ClientContainer = styled.div`
  ${forBreakpoint(Breakpoint.Phone, css`
    margin-left: 1.5rem;
  `)};
  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    margin: 0;
  `)};
`;

const Logo = styled(Img)`
  width: 100%;
  height: 100%;

  ${forBreakpoint(Breakpoint.Phone, css`
    display:none;
  `)};
    ${forBreakpoint(Breakpoint.TabletLandscape, css`
    display:block;
  `)};
`;

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
`;

const StyledColumn = styled(Column)`
    ${forBreakpoint(Breakpoint.Phone, css`
    margin-bottom: 0.6rem;
  `)};
`;
const Tag = styled(Text)`
  margin-right: 1rem;
  line-height: initial;
`;
