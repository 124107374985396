import { css } from '@emotion/core';
import styled from '@emotion/styled/macro';
import { graphql, Link } from 'gatsby';
import React, { Fragment } from 'react';

import { ProjectOverview } from '../components/ProjectOverview/ProjectOverview';
import { Root } from '../components/Root';
import { Heading } from '../components/UI/Heading';
import { NoSelect } from '../components/UI/NoSelect';
import BackgroundLetters from '../graphics/BackgroundLetters.svg';
import { Breakpoint } from '../types';
import { forBreakpoint } from '../utils/forBreakpoint';

export const query = graphql`
query data {
    allSanityProject {
      edges {
        node {
          Client
          Slug
          Tags
          ImagesGallery {
            asset {
              fluid(maxWidth: 1200) {
                aspectRatio
                base64
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  }
`;

export default ({ data }) => {
  const projectInfo = data.allSanityProject.edges;

  return (
    <Root includeFooter={true}>
      <BackgroundImage src={BackgroundLetters} alt="Background letters" />
      <StyledHeading type="h2"> Projecten</StyledHeading>
      <Container>

        {projectInfo.map((project, key) => {
          const p = project.node;
          return (
            <Fragment key={key}>
              {key % 2 === 0 ? (
                <Link to={p.Slug}>
                  <ProjectOverview key={key} project={p} />
                </Link>
              ) :
                <Link to={p.Slug}>
                  <ProjectOverview key={key} project={p} flexDirection="row-reverse" />
                </Link>
              }
            </Fragment>
          );
        })}

      </Container>
    </Root>
  );
};

const BackgroundImage = styled.img`
  ${NoSelect()};

  ${forBreakpoint(Breakpoint.Phone, css`
    display: none;
  `)}

  ${forBreakpoint(Breakpoint.TabletLandscape, css`
    display: block;
    position: absolute;
    left:-4rem;
    top: -5rem;
    z-index: 0;
  `)};
`;

const StyledHeading = styled(Heading)`
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
    font-size: 2.5rem !important;

   ${forBreakpoint(Breakpoint.TabletLandscape, css`
    display: block;
    margin-top: 8rem;
    margin-bottom: 6rem;
  `)};
`;

const Container = styled.div`
  margin-bottom: 10rem;
`;